.instruction-modal {
  width: 70rem;
}
.product-code {
  display: flex;
  flex-direction: row;
}

.style {
  margin-right: 24px;
}